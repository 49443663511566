<!-- @author panezhang -->
<!-- @email panezhang@yangqianguan.com -->
<!-- @date 2018-08-08 00:11:02.566 -->
<!-- @desc generated by yqg-cli@0.3.1 -->

<template>
    <div
        :style="style"
        class="yqg-indicator"
        @transitionend="onTransitionEnd()"
    />
</template>

<script type="text/babel">

    const STATUS = {
        STOPPED: 'STOPPED',
        MOVING: 'MOVING'
    };

    const DURATION = {
        INIT: 0.01, // for fast reset progress
        STEP: 0.8,
        SPLASH: 0.2 // for start and end
    };

    const PROGRESS = {
        INIT: 0,
        FULL: 100,
        MAX_WAITING: 80
    };

    export default {
        name: 'YqgIndicator',

        props: {
            color: {
                type: String,
                required: true
            },

            height: {
                type: [String, Number],
                default: 1
            }
        },

        data() {
            return {
                status: STATUS.STOPPED,
                progress: PROGRESS.INIT,
                duration: DURATION.INIT
            };
        },

        computed: {
            style() {
                const {color, height, progress, duration} = this;

                return {
                    backgroundColor: color,
                    height: `${height}px`,
                    width: `${progress}%`,
                    ...(duration ? {transition: `width ${duration}s`} : {})
                };
            }
        },

        mounted() {
            const {$router} = this;

            this.unregisterBeforeEachHook = $router.beforeEach((to, from, next) => {
                this.start();
                next();
            });

            this.unregisterAfterEachHook = $router.afterEach(() => this.end());
        },

        destroyed() {
            this.unregisterBeforeEachHook();
            this.unregisterAfterEachHook();
        },

        methods: {
            start() {
                if (this.status === STATUS.STOPPED) {
                    this.status = STATUS.MOVING; // reset status to MOVING
                    this.$nextTick(this.move);
                }

                this.clear();
            },

            move() {
                if (this.status === STATUS.STOPPED) {
                    return;
                }

                const {progress, duration} = this;
                this.duration = (progress === PROGRESS.INIT) ? DURATION.SPLASH : duration + DURATION.STEP;
                if (this.progress < PROGRESS.MAX_WAITING) {
                    this.progress += ((PROGRESS.FULL - progress) / 2); // will trigger a transition
                }
            },

            end() {
                // always reset status to STOPPED
                this.status = STATUS.STOPPED;
                if (this.progress > PROGRESS.INIT) {
                    this.duration = DURATION.SPLASH; // will trigger a transition
                    this.progress = PROGRESS.FULL;
                } else { // if not start moving yet
                    this.clear();
                }
            },

            clear() {
                this.progress = PROGRESS.INIT;
                this.duration = DURATION.INIT;
            },

            onTransitionEnd() {
                if (this.progress < PROGRESS.FULL) {
                    this.move();
                } else {
                    this.$nextTick(this.clear);
                }
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-indicator {
        width: 0;
    }

</style>
